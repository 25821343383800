export default class SignableDocumentFilter {

    customerType: string | null = null;

    customerId: number | null = null;

    documentType: string | null = null;

    customer: string | null = null;

    documentSubType: string | null = null;

    page = 1;

}